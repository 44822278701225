import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { ICycle } from "../types/cycle";
import {
  activateCycle,
  deactivateCycle,
  getCycle,
  getCycles,
  saveCycle,
} from "../services/cycle";
import { KVPair, ListPage, PaginationParams } from "../types/commons";

export function useGetCycles(
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[]
): UseQueryResult<ListPage<ICycle>> {
  return useQuery(
    ["cycle", params, filters, sorting],
    () => getCycles(params, filters, sorting),
    {
      retry: false,
    }
  );
}

export function useGetCycle(id: number): UseQueryResult<ICycle> {
  return useQuery(["cycle", id], () => getCycle(id), {
    retry: false,
  });
}

export function useInvalidateCycle(id: number) {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cycle", id] }),
  });
}

export function useActivateDeactivateCycle(id: number) {
  const queryClient = useQueryClient();
  return useMutation(
    (action: "activate" | "deactivate") =>
      action === "activate" ? activateCycle(id) : deactivateCycle(id),
    {
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: ["cycle", id] }),
    }
  );
}

export function useSaveCycle(id: number) {
  const queryClient = useQueryClient();
  return useMutation((cycle: ICycle) => saveCycle(cycle), {
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cycle", id] }),
  });
}
