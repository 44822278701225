import { IUserInfo } from '../types/auth';
import { restapi } from './restapi';

export function getToken() {
    return localStorage.getItem('Token');
}


export function clearToken() {
    localStorage.setItem('Token', '');
}

export function getUserDetails(): Promise<IUserInfo> {
    return restapi.get<IUserInfo>('/api-auth/current/').then(r => r.data);
}
