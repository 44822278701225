import { Button, Text } from "@wfp/react";
import { useTranslation } from "react-i18next";
import { IDelivery } from "../../types/delivery";
import { useGetDelivery } from "../../hooks/delivery";
import { Delete, Checkmark } from "@wfp/icons-react";
import { formatDate } from "../../utils/format";

interface Props {
  item: IDelivery;
  readonly?: boolean; // disable actions
  hideState?: boolean; // hide state
  acceptReturn?: () => void;
  rejectReturn?: () => void;
  undoPickup?: () => void;
  accept?: (delivery: IDelivery) => void;
  reject?: (delivery: IDelivery) => void;
  modify?: () => void;
  onClick?: () => void; // selection
}

export const DeliveryCardDetail = (props: Props) => {
  const { t } = useTranslation();

  const { data } = useGetDelivery(props.item.id);

  function handleAccept() {
    if (props.accept) {
      props.accept(props.item);
    }
  }

  function handleReject() {
    if (props.reject) {
      props.reject(props.item);
    }
  }

  return (
    <div
      className="flex items-start flex-col w-full wfp--card-box rounded-lg p-5 my-5"
      onClick={props.onClick}
    >
      <div className="flex flex-col space-y-3 w-full">
        <div className="flex justify-between mb-5">
          <p className="">
            {t("DeliveryID")}: <strong>{props.item.id}</strong>
          </p>
          <p>
            {t("State")}: <strong>{props.item.state}</strong>
          </p>
        </div>
        <div className="mr-auto">
          {t("ScheduleFrom")}:
          <strong>{formatDate(props.item.scheduledFrom)}</strong>
        </div>
        <div className="mr-auto">
          {t("ScheduleTo")}:
          <strong>{formatDate(props.item.scheduledTo)}</strong>
        </div>
        <Text kind="story-title" className="text-lg text-left text-slate-500">
          {t("Description")}
        </Text>
        <div className="mr-auto">{data && <p>{data.beneficiaryEmail}</p>}</div>
        <Text kind="story-title" className="text-xl text-left text-slate-500	">
          {t("BasketDetails")}
        </Text>
        <div className="text-start">
          {t("Cycle")}: {props.item.cycleName}
        </div>
        {/* <List kind="bullets" className="text-start">
          {(basketItems?.results || []).map((i, idx) => (
            <ListItem key={idx} className="">
              <div className="flex justify-between w-3/5">
                <div>{i.name}</div>
                <div>
                  {" "}
                  {t("Quantity")}: {i.quantity}
                </div>
              </div>
            </ListItem>
          ))}
        </List> */}
      </div>
      {!props.readonly && (
        <div className="flex justify-between w-full mt-8">
          <Button
            onClick={handleReject}
            kind="danger"
            icon={Delete}
            className="rounded-lg"
          >
            <div className="flex">
              <div className="px-2">{t("Reject")}</div>
            </div>
          </Button>
          <Button
            onClick={handleAccept}
            kind="primary"
            icon={Checkmark}
            className="rounded-lg"
          >
            <div className="flex">
              <div className="px-2">{t("Accept")}</div>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
