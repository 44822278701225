import { formatQueryOptions, restapi } from './restapi';
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import { ICycle } from "../types/cycle";

export function getCycles(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<ICycle>> {
    const qs = formatQueryOptions(params, filters, sorting);
    return restapi.get<ListPage<ICycle>>(`/api/v1/salaty_core/cycle/?${qs}`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getCycle(id: number): Promise<ICycle> {
    return restapi.get<ICycle>(`/api/v1/salaty_core/cycle/${id}/`).then((res) => res.data);
}

export function saveCycle(data: ICycle): Promise<ICycle> {
    if (!data.id) {
        return restapi.post<ICycle>('/api/v1/salaty_core/cycle/', data).then((res) => res.data);
    } else {
        return restapi.patch<ICycle>(`/api/v1/salaty_core/cycle/${data.id}/`, data).then((res) => res.data);
    }
}

export function activateCycle(id: number): Promise<ICycle> {
    return restapi.post<ICycle>(`/api/v1/salaty_core/cycle/${id}/activate/`).then((res) => res.data);
}

export function deactivateCycle(id: number): Promise<ICycle> {
    return restapi.post<ICycle>(`/api/v1/salaty_core/cycle/${id}/deactivate/`).then((res) => res.data);
}

export function bulkCreateCycle(id: number, baskets: number[], beneficiary: number): Promise<ICycle> {
    const qs = `?__all__=${beneficiary}`
    return restapi.post<ICycle>(`/api/v1/salaty_core/cycle/${id}/bulk/${qs}`, {baskets: baskets}).then((res) => res.data);
}