import * as React from 'react';
import { Input } from '@wfp/react';

interface ToggleProps extends React.ComponentPropsWithRef<'input'> {
  defaultToggled?: boolean;
  toggled?: boolean;
  labelA?: string;
  labelB?: string;
  onToggle?: (value: boolean, htmlFor: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  id?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  const { defaultToggled = false, id, labelA = '', labelB = '', ...other } = props;

  const htmlFor = id ? id : other.name;

  const otherInputProps = other as React.ComponentPropsWithRef<'input'>;

  return (
    <Input {...props}>
      <input
        {...otherInputProps}
        defaultChecked={defaultToggled}
        type='checkbox'
        id={htmlFor}
        className={`wfp--toggle`}
      />

      <label className={`wfp--toggle__label`} htmlFor={htmlFor}>
        <span className={`wfp--toggle__text--left`}>{labelA}</span>
        <span className={`wfp--toggle__appearance`} />
        <span className={`wfp--toggle__text--right`}>{labelB}</span>
      </label>
    </Input>
  );
};

Toggle.displayName = 'Toggle';

export default Toggle;
