import { IBeneficiary } from "../types/beneficiary";
import { formatQueryOptions, restapi } from "./restapi";
import { PaginationParams, KVPair, ListPage } from "../types/commons";

export function getBeneficiaries(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<IBeneficiary>> {
    const qs = formatQueryOptions(params, filters, sorting);
    return restapi.get<ListPage<IBeneficiary>>(`/api/v1/salaty_core/beneficiary/?${qs}`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getBeneficiary(id: number): Promise<IBeneficiary> {
    return restapi.get<IBeneficiary>(`/api/v1/salaty_core/beneficiary/${id}/`).then((res) => res.data);
}

export function saveBeneficiary(data: IBeneficiary): Promise<IBeneficiary> {
    if (!data.id) {
        return restapi.post<IBeneficiary>('/api/v1/salaty_core/beneficiary/', data).then((res) => res.data);
    } else {
        return restapi.patch<IBeneficiary>(`/api/v1/salaty_core/beneficiary/${data.id}/`, data).then((res) => res.data);
    }
}
export function deleteBeneficiary(id: number): Promise<IBeneficiary> {
    return restapi.post<IBeneficiary>(`/api/v1/salaty_core/beneficiary/${id}/disable/`).then((res) => res.data);
}
