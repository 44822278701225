export interface IUserInfo {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    address: string;
    role: Role;
}

export enum Role {
    Beneficiary = 'beneficiary',
    WFPManager = 'wfpmanager',
}
