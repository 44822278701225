import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  BlockNotification,
  Button,
  Text,
  Modal,
  Loading,
} from "@wfp/react";
import { useConfirmDelivery, useGetDelivery } from "../../hooks/delivery";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LoadingCenter } from "../commons/loading-center";
import { useGetBaskets } from "../../hooks/basket";
import BasketTable from "../basket/basket-table";
import { useState } from "react";
import { PaginationParams } from "../../types/commons";
import DeliveryEdit from "./delivery-edit";
import { DeliveryState } from "../../types/delivery";
import BeneficiaryCard from "../beneficiary/beneficiary-card";
import { useGetBeneficiary } from "../../hooks/beneficiary";
import { DeliveryCardDetail } from "./delivery-card-detail";

function DeliveryDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const {
    data: delivery,
    isLoading,
    isError,
    refetch,
  } = useGetDelivery(Number(id));
  const {
    data: baskets,
    isLoading: isLoadingBaskets,
    isError: isErrorBaskets,
  } = useGetBaskets({ pageNumber: 1 });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    mutate: confirmDelivery,
    isLoading: isLoadingConfirm,
    error: errorConfirm,
  } = useConfirmDelivery();

  const {
    data: beneficiary,
    isLoading: isLoadingBeneficiary,
    isError: isErrorBeneficiary,
  } = useGetBeneficiary(Number(delivery?.beneficiary), !!delivery);

  const handleConfirmDelivery = (id: number) => {
    confirmDelivery(id);
    refetch();
    setOpenConfirmModal(false);
  };

  return (
    <div>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href={`/cycles/${delivery?.cycle}`}>
          {!!delivery?.cycleName ? delivery.cycleName : ""}
        </BreadcrumbItem>
        <BreadcrumbItem disableLink>{t("Delivery")}</BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>{t("Error")}</div>}
      {!!errorConfirm && (
        <BlockNotification kind="error">{t("ErrorConfirm")}</BlockNotification>
      )}
      {!!delivery && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Details")}
              </Text>
              <div className="flex justify-end justify-between space-x-2">
                <DeliveryEdit item={delivery} cycleId={delivery.cycle} />
                {delivery.state === DeliveryState.BOOKED && (
                  <Button
                    kind="primary"
                    onClick={() => setOpenConfirmModal(true)}
                    disabled={isLoadingConfirm}
                  >
                    {t("Confirm")}
                  </Button>
                )}
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500 ">{t("Code")}</span>
                <p> {delivery.code}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateFrom")}: </span>
                <p>{delivery.scheduledFrom}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateTo")}: </span>
                <p>{delivery.scheduledTo}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("State")}: </span>
                <p>{delivery.state}</p>
              </div>
            </div>
            <Text kind="story-title" className="text-2xl text-justify mt-8">
                {t("Beneficiary")}
              </Text>
            {!!beneficiary && 
            <div className="w-2/5">
            <BeneficiaryCard readOnly item={beneficiary} />
            </div>
            }
          </div>
          <div className="text-left mt-8">
            <div className="flex align-middle justify-between">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Baskets")}
              </Text>
            </div>
            {isLoadingBaskets && <LoadingCenter />}
            {isErrorBaskets && <div>Error...</div>}
            {!!baskets && baskets.count > 0 ? (
              <div className="flex-col">
                <BasketTable
                  page={baskets}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={() => {}}
                />
              </div>
            ) : (
              <BlockNotification
                hideCloseButton={false}
                kind="info"
                title={t("NoDeliveries")}
                subtitle={t("NoDeliveriesInfo")}
              />
            )}
          </div>
          {openConfirmModal && (
            <Modal
              open={true}
              onRequestSubmit={() => handleConfirmDelivery(delivery.id)}
              onRequestClose={() => setOpenConfirmModal(false)}
              modalHeading={t("Confirm")}
              primaryButtonText={t("Confirm")}
              secondaryButtonText={t("Cancel")}
              width="full"
              children={
                <>
                  <Text>
                    {t("AreYouSureTo")} {t("Confirm")} {t("Delivery")}
                  </Text>
                  {isLoadingConfirm && <Loading withOverlay={false} small />}
                </>
              }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default DeliveryDetail;
