import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockNotification, Text, useMediaQuery } from "@wfp/react";
import { Role } from '../../types/auth';
import { KVPair, PaginationParams } from '../../types/commons';
import { useLoggedUser } from '../../hooks/auth';
import useGetBeneficiaries from "../../hooks/beneficiary";
import { LoadingCenter } from '../commons/loading-center';
import BeneficiaryAddEdit from "./beneficiary-add-edit";
import BeneficiaryCard from "./beneficiary-card";
import BeneficiaryTable from "./beneficiary-table";

function BeneficiaryPage() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [filters, setFilters] = useState<KVPair[] | undefined>();
  const [sorting, setSorting] = useState<string[] | undefined>();
  const { data: beneficiariesPage, isLoading, isError } = useGetBeneficiaries(paginationParams, filters, sorting);
  const user = useLoggedUser();
  /*
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<IBeneficiary | undefined>();

  const handleOnSearch = (evt: any) => {
    const keyword = evt.target.value.toLowerCase();
    if (keyword.length > 2) {
      setFilters([{key: 'email_ic', value: keyword}]);
    } else {
      setSelectedBeneficiary(undefined);
    }
    setSearchValue(keyword);
  };

  const onClickBeneficiary = (value: IBeneficiary) => {
    setSelectedBeneficiary(value);
    setSearchValue(value.email);
  }
          <Search
            className="search-sidebar"
            name="input-name"
            labelText={t("Search")}
            onChange={handleOnSearch}
            value={searchValue}
          />
          <div className="wfp--sidebar-item-content sidebar__container border-0 max-h-screen">
              <BeneficiaryList beneficiaries={beneficiariesPage.results} onClick={onClickBeneficiary} />
          </div>
          <div className="wfp--content-section">
            <div className="ml-5">
              {!!selectedBeneficiary && <BeneficiaryDetail instance={selectedBeneficiary} />}
            </div>
          </div>
*/

  return (
    <>
      <div className="mt-10">
          <div className="flex justify-between">
            <Text kind="story-title" className="text-2xl text-left">
              {t("Beneficiaries")}
            </Text>
            {!isSmallScreen && user?.role === Role.WFPManager && <BeneficiaryAddEdit />}
         </div>
          {isLoading && <LoadingCenter />}
          {isError && <div>Error</div>}
          {!!beneficiariesPage &&  beneficiariesPage.count > 0 ? (
            <>
            {isSmallScreen ? (
              <>
                  {(beneficiariesPage.results).map((item) => (
                    <div  className="m-5 wfp--card-box p-4 flex flex-col">  
                      <BeneficiaryCard key={item.id} item={item} />
                    </div>
                  ))}
              </>
            ) : (
                <BeneficiaryTable
                  page={beneficiariesPage}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={setSorting}
                />
            )}
            </>)
            :(
              <BlockNotification
              kind="info"
              title={t("NoBeneficiaries")}
              subtitle={t("NoBeneficiariesDescription")}
            />
            )}
  
      </div>
    </>
  );
}

export default BeneficiaryPage;
