import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbHome,
  Text,
  Button,
  Modal,
  Loading,
  BlockNotification,
  Search,
} from "@wfp/react";
import { KVPair, PaginationParams } from "../../types/commons";
import { useGeCycleDeliveries } from "../../hooks/delivery";
import { useActivateDeactivateCycle, useGetCycle } from "../../hooks/cycle";
import { LoadingCenter } from "../commons/loading-center";
import DeliveryTable from "../delivery/delivery-table";
import AddDelivery from "../delivery/delivery-add";
import CycleAddEdit from "./cycle-add-edit";
import { Download } from "@wfp/icons-react";
import { useGetDeliveryReport } from "../../hooks/report";
function CycleDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: cycle, isLoading, isError } = useGetCycle(Number(id));
  const {
    mutate: activateDeactivate,
    isLoading: isLoadingActivateDeactivate,
    error: errorActivateDeactivate,
  } = useActivateDeactivateCycle(Number(id));

  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [filters, setFilters] = useState<KVPair[] | undefined>();

  const [sorting, setSorting] = useState<string[] | undefined>(["beneficiary"]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {
    data: deliveryPage,
    isLoading: isLoadingDeliveries,
    isError: isErrorDeliveries,
    refetch: refetchDeliveries,
  } = useGeCycleDeliveries(Number(id), paginationParams, sorting, filters);

  const {
    data: reportDelivery,
    isLoading: isLoadingReport,
    isError: isErrorReport,
    refetch,
  } = useGetDeliveryReport(Number(id));

  const handleActivateDeactivate = () => {
    if (!!cycle) {
      activateDeactivate(cycle.isActive ? "deactivate" : "activate");
    }
    setOpenConfirmModal(false);
  };

  const [searchValue, setSearchValue] = useState<string>("");

  const handleOnSearch = (evt: any) => {
    const keyword = evt.target.value.toLowerCase();
    if (keyword.length >= 2) {
      setFilters([{ key: "beneficiary_ic", value: keyword }]);
    }
    if (keyword.length === 0) {
      setFilters(undefined);
    }
    setSearchValue(keyword);
  };

  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/cycles">{t("Cycles")}</BreadcrumbItem>
        <BreadcrumbItem disableLink>{cycle?.name}</BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>Error...</div>}
      {!!cycle && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Details")}
              </Text>
              <div className="flex justify-end space-x-2">
                <CycleAddEdit item={cycle} kind={"secondary"} />
                <AddDelivery cycleId={cycle.id} />
                <Button
                  kind="primary"
                  onClick={() => setOpenConfirmModal(true)}
                  disabled={isLoadingActivateDeactivate}
                >
                  {cycle.isActive ? t("Deactivate") : t("Activate")}
                </Button>
              </div>
            </div>
            {!!errorActivateDeactivate && (
              <BlockNotification
                hideCloseButton={false}
                kind="error"
                title={t("Error")}
                subtitle={t("ActivationError")}
              />
            )}
            <div className="w-1/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500 ">{t("Name")}</span>
                <p> {cycle.name}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateFrom")}: </span>
                <p>{cycle.dateFrom}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("DateTo")}: </span>
                <p>{cycle.dateTo}</p>
              </div>
            </div>
          </div>
          <div className="text-left mt-8">
            <div className="flex align-middle justify-between">
              <Text kind="story-title" className="text-2xl text-justify">
                {t("Deliveries")}
              </Text>
              {!!deliveryPage && deliveryPage.count > 0 && (
                <div>
                  <Button
                    onClick={() => refetch()}
                    kind="secondary"
                    icon={Download}
                    disabled={isLoadingReport}
                  >
                    {t("Export")}
                  </Button>
                </div>
              )}
            </div>
            <div className="w-1/5 mb-3">
              <Search
                className="filter-beneficiary"
                name="beneficiary-email"
                onChange={handleOnSearch}
                value={searchValue}
              />
            </div>
            {isLoadingDeliveries && <LoadingCenter />}
            {isErrorDeliveries && <div>Error...</div>}
            {!!deliveryPage && deliveryPage.count > 0 && (
              <div className="flex-col">
                <DeliveryTable
                  page={deliveryPage}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={setSorting}
                />
              </div>
            )}
            {!!deliveryPage && deliveryPage.count === 0 && (
              <BlockNotification
                hideCloseButton={false}
                kind="info"
                title={t("NoDeliveries")}
                subtitle={t("NoDeliveriesInfo")}
              />
            )}
          </div>
        </div>
      )}
      {openConfirmModal && (
        <Modal
          open={true}
          onRequestSubmit={handleActivateDeactivate}
          onRequestClose={() => setOpenConfirmModal(false)}
          modalHeading={t("Confirm")}
          primaryButtonText={t("Confirm")}
          secondaryButtonText={t("Cancel")}
          width="full"
          children={
            <>
              <Text>
                {t("AreYouSureTo")}{" "}
                {cycle!.isActive ? t("Deactivate") : t("Activate")}
              </Text>
              {isLoadingActivateDeactivate && (
                <Loading withOverlay={false} small />
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default CycleDetail;
