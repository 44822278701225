import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { IDelivery } from '../types/delivery';
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import { confirmDelivery, getDeliveries, getDeliveryDetail, saveDelivery } from '../services/delivery';

export function useGetDeliveries(params: PaginationParams, filters?: KVPair[], sorting?: string[]): UseQueryResult<ListPage<IDelivery>> {
    return useQuery(['delivery', params, filters, sorting], () => getDeliveries(params, filters, sorting), {
        retry: false
    });
}

export function useGetDelivery(id: number): UseQueryResult<IDelivery> {
    return useQuery(['delivery'], () => getDeliveryDetail(id), {
        retry: false
    });
}

export function useGeCycleDeliveries(cycleId: number, params: PaginationParams = { pageNumber: 1 }, sorting?: string[], filters?: KVPair[]): UseQueryResult<ListPage<IDelivery>> {
    filters = filters ? filters.concat([{key: 'cycle', value: cycleId.toString()}]) : [{key: 'cycle', value: cycleId.toString()}];
    return useQuery(['delivery',  params, sorting, filters], () => getDeliveries(params, filters, sorting), {
        retry: false
    });
}

export function useInvalidateDeliveries(params: PaginationParams = { pageNumber: 1 }) {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery',  params]})
    });
}

export function useConfirmDelivery() {
    const queryClient = useQueryClient();
    return useMutation((id: number) => confirmDelivery(id), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['delivery']})
    });
}