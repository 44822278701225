import { ListPage, PaginationParams } from '../types/commons';
import { IBasketItem } from '../types/basket';
import { formatQueryOptions, restapi } from './restapi';


export function getBasketItems(basketId: number, params: PaginationParams, sorting?: string[]): Promise<ListPage<IBasketItem>> {
    const qs = formatQueryOptions(params, [{ key: 'basket', value: basketId.toString() }], sorting);
    return restapi.get<ListPage<IBasketItem>>(`/api/v1/salaty_core/basket_item/?${qs}`).then((res) => ({ ...res.data, filtering: undefined, sorting: sorting }));
}


export function saveBasketItem(data: IBasketItem): Promise<IBasketItem> {
    if (!data.id) {
        return restapi.post<IBasketItem>('/api/v1/salaty_core/basket_item/', data).then((res) => res.data);
    } else {
        return restapi.patch<IBasketItem>(`/api/v1/salaty_core/basket_item/${data.id}/`, data).then((res) => res.data);
    }
}

export function deleteBasketItem(data: IBasketItem): Promise<IBasketItem> {
    return restapi.delete<IBasketItem>(`/api/v1/salaty_core/basket_item/${data.id}/`).then((res) => res.data);

}
