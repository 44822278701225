import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
    Link,
    SubNavigation,
    SubNavigationContent,
    SubNavigationGroup,
    SubNavigationHeader,
    SubNavigationItem,
    SubNavigationList,
    SubNavigationTitle,
    useMainNavigation,
} from "@wfp/react";

export const getLanguage = () => {
    return localStorage.getItem('lang');
}

export default function LanguageMenu() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { onChangeSub } = useMainNavigation();

  const selectLanguage = (lang: string) => {
    i18n.changeLanguage(lang).then(() => {
        localStorage.setItem('lang', lang);
        if (lang === 'ar') {
            i18n.dir("ltr");
        }
        navigate(0);
    });
    onChangeSub('close');
};
  
  return (
    <SubNavigation className="text-center">
      <SubNavigationHeader>
        <SubNavigationTitle>{t("choose_language")}</SubNavigationTitle>
      </SubNavigationHeader>
      <SubNavigationContent>
        <SubNavigationList>
          <SubNavigationGroup>
            <SubNavigationItem>
              <Link
                className={i18n.language === "en" ? "active" : ""}
                onClick={() => selectLanguage("en")}
              >
                {" "}
                English <i>(إنجليزي)</i>
              </Link>
            </SubNavigationItem>
            <SubNavigationItem>
              <Link
                className={i18n.language === "ar" ? "active" : ""}
                onClick={() => selectLanguage("ar")}
              >
                {" "}
                Arabic <i>(عربي)</i>
              </Link>
            </SubNavigationItem>
          </SubNavigationGroup>
        </SubNavigationList>
      </SubNavigationContent>
    </SubNavigation>
  );
}
