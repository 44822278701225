import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getUserDetails } from "../services/auth";
import { IUserInfo } from '../types/auth';


export function useOptionalUser(): IUserInfo | undefined {
    const queryClient = useQueryClient();
    const queryResult = useQuery(['user', 'optional'], {
       queryFn: () => queryClient.getQueryData(['user', 'current']) as IUserInfo | undefined
    });
    return queryResult.data;
}

// read current user
export function useLoggedUser() {
    const queryClient = useQueryClient();
    const userQuery = useQuery(['user', 'current'], () => getUserDetails(), {
        staleTime: Infinity,  // never reload
        cacheTime: Infinity,  // never reload
        retry: false,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'optional'] }),
        onError: () => console.log('Failed to fetch user')
    });
    return userQuery.data;
}

export function useInvalidateUser() {
    const queryClient = useQueryClient();
    return useMutation((user: IUserInfo|undefined) => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'current'] })
    });
}
