import { useTranslation } from "react-i18next";
import { Form, TextInput } from "@wfp/react";
import { IBasket } from "../../types/basket";
import { IBasketError } from '../../models/validate-basket';

interface BasketFormProps {
  item: IBasket;
  error?: IBasketError;
  onChange: (basket: IBasket) => void;
}

function BasketForm(props: BasketFormProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Form>
        <TextInput
          invalid={!!props.error?.nameAr}
          invalidText={t(props.error?.nameAr?.toString() || '')}
          labelText={t("nameAr")!}
          name="nameAr"
          onChange={(e: any) => props.onChange({...props.item, nameAr: e.target.value})}
          required
          type='text'
          value={props.item.nameAr || ''}
        />
        <TextInput
          invalid={!!props.error?.nameEn}
          invalidText={t(props.error?.nameEn?.toString() || '')}
          labelText={t("nameEn")!}
          name="nameEn"
          onChange={(e: any) => props.onChange({...props.item, nameEn: e.target.value})}
          required
          type='text'
          value={props.item.nameEn || ''}
        />
      </Form>
    </div>
  );
}

export default BasketForm;
