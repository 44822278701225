import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Text, useMediaQuery } from "@wfp/react";
import { KVPair, PaginationParams } from '../../types/commons';
import { IBasket } from "../../types/basket";
import { IBasketError, validateBasket } from '../../models/validate-basket';
import { saveBasket } from '../../services/basket';
import { useGetBaskets } from "../../hooks/basket";
import { LoadingCenter } from '../commons/loading-center';
import BasketTable from "./basket-table";
import BasketForm from "./basket-form";

function BasketPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({ pageNumber: 1 });
  const [sorting, setSorting] = useState<string[] | undefined>(['id']);
  const [filters, setFilters] = useState<KVPair[] | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const { data, isLoading, isError } = useGetBaskets(paginationParams, filters, sorting);
  const [selectedBasket, setSelectedBasket] = useState<IBasket>();
  const [basketError, setBasketError] = useState<IBasketError>();

  const handleAddItem = () => {
      if (!!data) {
          setOpenModal(true);
          setBasketError(undefined);
          setSelectedBasket({} as IBasket);
      }
  }

  const handleAddItemSubmit = () => {
      if (!!selectedBasket) {
          validateBasket(selectedBasket)
              .then((basket) =>
                  saveBasket(basket)
                      .then(res => {
                          setOpenModal(false);
                          setBasketError(undefined);
                          setSelectedBasket(res);
                          navigate(`/baskets/${res.id}`);
                      })
                      .catch(e => setBasketError(e as IBasketError)))
              .catch(e => setBasketError(e));
      }
  }

  const handleAddItemClose = () => {
    setOpenModal(false);
      setBasketError(undefined);
      setSelectedBasket(undefined);
  }

  return (
    <>
      <div className="mt-10 ">
        <div className="flex justify-between">
          <Text kind="story-title" className="text-2xl text-left">
            {t("Baskets")}
          </Text>

          <Button onClick={handleAddItem}>{t("AddBasket")}</Button>
        </div>
        {isLoading && <LoadingCenter />}
        {isError && <div>Error...</div>}
        {data && (
          <>
            {isSmallScreen ? (
              <>
                {data.results.map((basket) => (
                  <div className="m-5 wfp--card-box p-4" key={basket.id} onClick={() => navigate(`/baskets/${basket.id}`)}>
                    <div className="text-left m-4">
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("ID")}: </span>
                        <p> {basket.id}</p>
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-500">{t("Name")}: </span>
                        <p>{basket.name}</p>
                        </div>
                 
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="flex-col">
                  <BasketTable page={data} onChangePagination={setPaginationParams} onChangeSorting={setSorting}/>
              </div>
            )}
          </>
        )}
      </div>
      {openModal && selectedBasket && (
      <Modal
        open={true}
        onRequestClose={handleAddItemClose}
        onRequestSubmit={handleAddItemSubmit}
        modalHeading={t("AddBasket")}
        primaryButtonText={t("Add")}
        secondaryButtonText={t("Cancel")}
      >
        <BasketForm onChange={setSelectedBasket} item={selectedBasket!} error={basketError} />
      </Modal>
      )}
    </>
  );
}

export default BasketPage;
