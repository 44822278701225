import { MainNavigation, MainNavigationItem, Text } from "@wfp/react";
import UserMenu from "./UserMenu";
import LanguageMenu from "./LangMenu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();


  return (
    <MainNavigation
      pageWidth="lg"
      logo={
        <div onClick={() => navigate("/")}>
          <Text className="wfp--text wfp--text__story-h1 hover:cursor-pointer">
            {t("Salaty")}
          </Text>
        </div>
      }
    >
      <UserMenu />
      <MainNavigationItem
        className="wfp--main-navigation__user"
        subNavigation={<LanguageMenu />}
      >
        <span>{i18n.language}</span>
      </MainNavigationItem>
    </MainNavigation>
  );
};
