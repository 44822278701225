import { useTranslation } from "react-i18next";
import { Form, Text, TextInput } from "@wfp/react";

import { IBeneficiary } from "../../types/beneficiary";
import { IBeneficiaryError } from "../../models/validate-beneficiary";
import Toggle from "../commons/Toggle";
import { useState } from "react";

interface Props {
  item: IBeneficiary;
  error?: IBeneficiaryError;
  onChange: (
    beneficiary: IBeneficiary,
    field: "email" | "idmId" | "address" | "isActive"
  ) => void;
}
function BeneficiaryForm({ ...props }: Props) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(props.item.isActive || false);
  const ToggleActive = false;

  return (
    <Form>
      <div className="space-y-5">
        {!props.item.id && (
          <TextInput
            invalid={!!props.error?.email}
            invalidText={t(props.error?.email?.toString() || "")}
            labelText={t("email")!}
            name="email"
            onChange={(e: any) =>
              props.onChange({ ...props.item, email: e.target.value }, "email")
            }
            required
            type="email"
            value={props.item.email || ""}
            className="pb-5"
          />
        )}
        <TextInput
          invalid={!!props.error?.idmId}
          invalidText={t(props.error?.idmId?.toString() || "")}
          labelText={t("idmId")!}
          name="idmId"
          onChange={(e: any) =>
            props.onChange({ ...props.item, idmId: e.target.value }, "idmId")
          }
          required
          type="text"
          value={props.item.idmId || ""}
          className="pb-5"
        />
        <TextInput
          invalid={!!props.error?.address}
          invalidText={t(props.error?.address?.toString() || "")}
          labelText={t("address")!}
          name="address"
          onChange={(e: any) =>
            props.onChange(
              { ...props.item, address: e.target.value },
              "address"
            )
          }
          required
          type="text"
          value={props.item.address || ""}
          className="pb-5"
        />
        {ToggleActive && (
          <>
            <Text className="wfp--label">{t("IsActive")}</Text>
            <Toggle
              name="isActive"
              defaultChecked={isActive}
              checked={isActive}
              labelA={t("no")}
              labelB={t("yes")}
              onChange={(e: any) => {
                setIsActive(e.target.checked);
                props.onChange(
                  { ...props.item, isActive: e.target.checked },
                  "isActive"
                );
              }}
            />
          </>
        )}
      </div>
    </Form>
  );
}

export default BeneficiaryForm;
