import {ServerError} from "../types/commons";
import {IBasket} from "../types/basket";
import { ICycle } from "../types/cycle";


export interface ICycleError extends ServerError {
    dateFrom: string[] | undefined;
    dateTo: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
}

class CycleErrorInfo implements ICycleError {
    dateFrom: string[] | undefined;
    dateTo: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateCycle(item: ICycle): Promise<ICycle> {
    const error = new CycleErrorInfo();
    if (!item.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!item.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    if (!item.dateFrom) {
        error.add('dateFrom', 'date_from_is_required');
    }
    if (!item.dateTo) {
        error.add('dateTo', 'date_to_this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
