import { Button } from "@wfp/react";
import { Logo } from "../components/commons/logo";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function LogoutDone() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col">
        <Logo text_key="LogoutDone" />
        <div className="text-center">
          <Button onClick={() => navigate("/home")} color="primary">
            {t("Login")}
          </Button>
        </div>
      </div>
    </div>
  );
}
