import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
  Button,
  Modal,
  Text,
} from "@wfp/react";
import { AddCircle, Delete } from "@wfp/icons-react";
import { PaginationParams, ServerError } from "../../types/commons";
import { IBasketItem } from "../../types/basket";
import {
  IBasketItemError,
  validateBasketItem,
} from "../../models/validate-basket-item";
import { disableBasket } from "../../services/basket";
import { deleteBasketItem, saveBasketItem } from "../../services/basket-item";
import { useGetBasket } from "../../hooks/basket";
import {
  useGetBasketItems,
  useInvalidateBasketItems,
} from "../../hooks/basket-item";
import BasketItemForm from "../basket-item/basket-item-form";
import BasketItemTable from "../basket-item/basket-item-table";
import { LoadingCenter } from "../commons/loading-center";

function BasketDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [paginationParams, setPaginationParams] = useState<PaginationParams>({
    pageNumber: 1,
  });
  const [sorting, setSorting] = useState<string[] | undefined>(["id"]);
  const [selectedBasketItem, setSelectedBasketItem] = useState<IBasketItem>();
  const [basketItemError, setBasketItemError] = useState<IBasketItemError>();
  const [serverError, setServerError] = useState<ServerError>();

  const {
    data: basket,
    isLoading: isLoadingBasket,
    isError: isErrorBasket,
  } = useGetBasket(Number(id));
  const {
    data: itemsPage,
    isLoading: isLoadingItems,
    isError: isErrorItems,
  } = useGetBasketItems(Number(id), paginationParams, sorting);
  const { mutate: updateItemsPage } = useInvalidateBasketItems(Number(id));

  const handleAddItem = () => {
    if (!!basket) {
      setBasketItemError(undefined);
      setSelectedBasketItem({ basket: basket!.id, quantity: 1 } as IBasketItem);
      setOpenModal(true);
    }
  };

  const handleDeleteItem = (item: IBasketItem) => {
    deleteBasketItem(item)
      .then((res) => {
        updateItemsPage();
    }).catch((e) => setServerError(e as ServerError));
  };

  const handleAddItemSubmit = () => {
    if (!!selectedBasketItem) {
      validateBasketItem(selectedBasketItem)
        .then((basket) =>
          saveBasketItem(basket)
            .then((res) => {
              setOpenModal(false);
              setBasketItemError(undefined);
              setSelectedBasketItem(res);
              updateItemsPage();
            })
            .catch((e) => setBasketItemError(e as IBasketItemError))
        )
        .catch((e) => setBasketItemError(e));
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setBasketItemError(undefined);
    setSelectedBasketItem(undefined);
  };

  const handleDeleteBasketClose = () => {
    setOpenModal(false);
  };

  const handleDeleteBasketConfirm = () => {
    if (!!basket) {
      disableBasket(basket!.id)
        .then((res) => {
          setOpenModal(false);
          setServerError(undefined);
          navigate(`/baskets/`);
        })
        .catch((e) => setServerError(e as ServerError));
    }
  };

  const handleDeleteBasket = () => {
    setServerError(undefined);
    setOpenModal(true);
  };

  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/baskets">{t("Baskets")}</BreadcrumbItem>
        <BreadcrumbItem disableLink>{id}</BreadcrumbItem>
      </Breadcrumb>
      {isLoadingBasket && <LoadingCenter />}
      {isErrorBasket && <div>Error...</div>}
      {basket && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between">
              <Text kind="story-title" className="text-2xl">
                {t("BasketDetails")}
              </Text>
              <div className="flex gap-2">
                <Button
                  icon={Delete}
                  kind="danger"
                  onClick={handleDeleteBasket}
                >
                  {t("Delete")}
                </Button>
                <Button icon={AddCircle} onClick={handleAddItem}>
                  {t("AddBasketItem")}
                </Button>
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("ID")}: </span>
                <p>{basket.id}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Name")}: </span>
                <p>{basket.name}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("CreatedOn")}: </span>
                <p>{basket.createdOn}</p>
              </div>
            </div>
          </div>
          <div className="text-left mt-4">
            <Text kind="story-title" className="text-2xl text-justify">
              {t("BasketItems")}
            </Text>
            {isLoadingItems && <LoadingCenter />}
            {isErrorItems && <div>Error...</div>}
            {!!itemsPage && (
              <div className="flex-col">
                <BasketItemTable
                  page={itemsPage}
                  onChangePagination={setPaginationParams}
                  onChangeSorting={setSorting}
                  onDeleteRow={handleDeleteItem}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {openModal && !!selectedBasketItem && (
        <Modal
          open={true}
          onRequestClose={handleAddItemClose}
          onRequestSubmit={handleAddItemSubmit}
          modalHeading={t("AddBasketItem")}
          primaryButtonText={t("Add")}
          secondaryButtonText={t("Cancel")}
          width="full"
        >
          <BasketItemForm
            error={basketItemError}
            item={selectedBasketItem!}
            onChange={setSelectedBasketItem}
          />
        </Modal>
      )}{" "}
      {openModal && !selectedBasketItem && (
        <Modal
          open={true}
          onRequestClose={handleDeleteBasketClose}
          onRequestSubmit={handleDeleteBasketConfirm}
          modalHeading={t("DeleteBasket")}
          primaryButtonText={t("Confirm")}
          secondaryButtonText={t("Cancel")}
          width="full"
        >
          <Text>{t("ConfirmDeletion")}</Text>
          {serverError && (
            <Text kind="error">{serverError?.errors?.toString()}</Text>
          )}
        </Modal>
      )}
    </>
  );
}

export default BasketDetail;
