import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  BreadcrumbHome,
  BreadcrumbItem,
  Button,
  Modal,
  Text,
} from "@wfp/react";
import { LoadingCenter } from "../commons/loading-center";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBeneficiary } from "../../hooks/beneficiary";
import BeneficiaryAddEdit from "./beneficiary-add-edit";
import { useState } from "react";
import { deleteBeneficiary } from "../../services/beneficiary";

function BeneficiaryDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: beneficiary,
    isLoading,
    isError,
  } = useGetBeneficiary(Number(id));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteBeneficiary = () => {
    deleteBeneficiary(Number(id)).then(() => {
      navigate("/beneficiaries");
    })
  }
  return (
    <>
      <Breadcrumb className="mt-5">
        <BreadcrumbItem>
          <a href="/#">
            <BreadcrumbHome />
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem href="/beneficiaries">
          {t("Beneficiaries")}
        </BreadcrumbItem>
        <BreadcrumbItem disableLink>{beneficiary?.idmId}</BreadcrumbItem>
      </Breadcrumb>
      {isLoading && <LoadingCenter />}
      {isError && <div>Error...</div>}
      {!!beneficiary && (
        <div className="mt-8">
          <div className="text-justify">
            <div className="flex justify-between align-middle">
              <Text kind="story-title" className="text-2xl mb-3 text-justify">
                {t("Details")}
              </Text>
              <div className="flex gap-2">
                <Button
                  kind="danger"
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                >
                  {t("Delete")}
                </Button>
                <BeneficiaryAddEdit item={beneficiary} />
              </div>
            </div>
            <div className="w-2/5">
              <div className="flex justify-between mb-3">
                <span className="text-gray-500 ">{t("IDMID")}</span>
                <p> {beneficiary.idmId}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Email")}: </span>
                <p>{beneficiary.email}</p>
              </div>
              <div className="flex justify-between mb-3">
                <span className="text-gray-500">{t("Address")}: </span>
                <p>{beneficiary.address}</p>
              </div>
            </div>
          </div>
          {openDeleteModal && (
            <Modal
              open={true}
              onRequestSubmit={handleDeleteBeneficiary}
              onRequestClose={() => setOpenDeleteModal(false)}
              modalHeading={t("Delete")}
              primaryButtonText={t("Confirm")}
              secondaryButtonText={t("Cancel")}
            
            >{t("AreYouSureToDelete?")}</Modal>
          )}
        </div>
      )}
    </>
  );
}

export default BeneficiaryDetail;
