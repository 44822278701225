import { restapi } from "./restapi";

export function getDeliveryReport(id: number): Promise<boolean> {
    return restapi.get(`/api/v1/salaty_report/delivery/?cycle=${id}&__format__=csv`,{

        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'text/csv',
            //'Accept': 'text/csv'
        }
    }).then((res) => {
        const cd = res.headers['content-disposition'] || '';
        const mask = 'attachment; filename='
        const filename = cd.startsWith(mask) ? cd.slice(mask.length).replaceAll('\\', '').replaceAll('"', '').replaceAll("'", "") : 'report.csv';
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        return true;
    });
}