import { useTranslation } from "react-i18next";
import { Text } from "@wfp/react";
import { WfpLogoStandardBlackEn } from "@wfp/icons-react";

export function Logo(props: { text_key?: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col">
        <WfpLogoStandardBlackEn
          className="bg-blue"
          color={"blue"}
          width={"300"}
          height={"250"}
        />
        <Text className="text-center mb-5" kind="title">
          {t(!!props.text_key ? props.text_key! : "WelcomeToSalaty")}
        </Text>
      </div>
    </div>
  );
}
