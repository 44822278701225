import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as WfpTable,
  Pagination as WFPPagination,
  Button,
} from "@wfp/react";
import { PaginationParams, Sortable, SortMode } from "../../../types/commons";
import { ListPage } from "../../../types/commons";
import { Delete } from "@wfp/icons-react";

const DefaultPageSizeOptions = [5, 10, 20, 50, 100];

export interface TableProps<T> {
  onRowClick?: (row: T) => void;
  page: ListPage<T>;
  onChangePagination: (params: PaginationParams) => void;
  onChangeSorting: (sorting: string[]) => void;
  disablePagination?: boolean;
  onDeleteRow?: (row: T) => void;
}

interface PaginationProps<T> {
  page: ListPage<T> | undefined;
  onChange: (params: PaginationParams) => void;
  sizeOptions?: number[];
}

export interface ConfigurableTableProps<T> extends TableProps<T> {
  fields: string[];
  formatters?: {
    [field: string]: (value: T) => string;
  };
  headers: string[];
  sortables?: Sortable[];
  deleteAction?: (item: T) => void;
}

export function ConfigurableTable<T>(props: ConfigurableTableProps<T>) {
  const { t } = useTranslation();
  const [sortables, setSortables] = useState<Sortable[]>(props.sortables || []);

  useEffect(() => {
    const sortInfo = !!props.sortables ? [...props.sortables] : [];
    sortInfo.forEach((info) => (info.mode = SortMode.None));
    props.page.sorting?.forEach((sort) => {
      const field =
        sort.startsWith("+") || sort.startsWith("-") ? sort.slice(1) : sort;
      const idx = sortInfo.findIndex((s) => s.code === field);
      if (idx !== -1) {
        sortInfo!.at(idx)!.mode = sort.startsWith("-")
          ? SortMode.Descending
          : SortMode.Ascending;
      }
    });
    setSortables(sortInfo);
  }, [props.sortables, props.page]);

  const updateSorting = (sort: string, sorting: string[]): string[] => {
    const field =
      sort.startsWith("+") || sort.startsWith("-") ? sort.slice(1) : sort;
    const options = [field, `+${field}`, `-${field}`];
    const idx = sorting.findIndex((s) => options.includes(s));
    if (idx !== -1) {
      sorting.splice(idx, 1);
    }
    return sort.startsWith("+")
      ? [field, ...sorting]
      : sort.startsWith("-")
      ? [sort, ...sorting]
      : sorting;
  };

  const handleSorting = (idx: number) => {
    const sortable = sortables?.at(idx);
    if (!sortable?.enabled) return;
    sortable!.mode = (sortable!.mode + 1) % 3;
    const value =
      sortable.mode === SortMode.Ascending
        ? `+${sortable.code}`
        : sortable.mode === SortMode.Descending
        ? `-${sortable.code}`
        : sortable.code;
    const sorting = updateSorting(value, props.page.sorting || []);
    props.onChangeSorting(sorting);
  };

  const formatValue = (item: T, field: string) => {
    if (props.formatters?.hasOwnProperty(field)) {
      return props.formatters![field](item);
    }
    return (item as any)[field];
  };

  return (
    <>
      <WfpTable>
        <thead>
          <tr>
            {props.headers.map((item, idx) => (
              <th
                key={idx}
                onClick={() => handleSorting(idx)}
                className={sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}
              >
                {t(item)}
                {!!sortables && (
                  <span className="ml-0.5">
                    {sortables!.at(idx)?.mode === SortMode.Ascending
                      ? "\u25b2"
                      : sortables!.at(idx)?.mode === SortMode.Descending
                      ? "\u25bc"
                      : "\u2000"}
                  </span>
                )}
              </th>
            ))}

            {!!props.deleteAction && <th>{t("DeleteItem")}</th>}
          </tr>
        </thead>
        <tbody>
          {props.page.results.map((item, rowIdx) => (
            <tr
              key={rowIdx}
              onClick={() =>
                !!props.onRowClick ? props.onRowClick(item) : undefined
              }
              className={
                !!props.onRowClick
                  ? "hover:bg-slate-100 cursor-pointer"
                  : undefined
              }
            >
              {props.fields.map((field, colIdx) => (
                <td key={colIdx}>{formatValue(item, field)}</td>
              ))}

              {!!props.deleteAction && (
                <td>
                  <Button
                    small
                    kind="danger"
                    onClick={() => props.deleteAction?.(item)}
                    icon={Delete}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </WfpTable>
      {!props.disablePagination && (
        <Pagination page={props.page} onChange={props.onChangePagination} />
      )}
    </>
  );
}

export function Pagination<T>({
  page,
  onChange,
  sizeOptions = DefaultPageSizeOptions,
}: PaginationProps<T>) {
  return (
    <WFPPagination
      pageSize={page?.pageSize}
      pageSizes={sizeOptions}
      page={page?.pageNumber}
      totalItems={page?.count}
      onChange={(p) => onChange({ pageNumber: p.page, pageSize: p.pageSize })}
    />
  );
}
