import { formatQueryOptions, restapi } from './restapi';
import { IBasket, IBasketDelivery } from "../types/basket";
import { KVPair, ListPage, PaginationParams } from "../types/commons";


export function getBaskets(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<IBasket>> {
    const qs = formatQueryOptions(params, filters, sorting);
    return restapi.get<ListPage<IBasket>>(`/api/v1/salaty_core/basket/?${qs}`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getBasketDetail(id: number): Promise<IBasket> {
    return restapi.get<IBasket>(`/api/v1/salaty_core/basket/${id}/`).then((res) => res.data);
}

export function saveBasket(data: IBasket): Promise<IBasket> {
    if (!data.id) {
        return restapi.post<IBasket>('/api/v1/salaty_core/basket/', data).then((res) => res.data);
    } else {
        return restapi.patch<IBasket>(`/api/v1/salaty_core/basket/${data.id}/`, data).then((res) => res.data);
    }
}

export function disableBasket(id: number): Promise<IBasket> {
    return restapi.post<IBasket>(`/api/v1/salaty_core/basket/${id}/disable/`).then(res => res.data);
}
export function getBasketDelivery(): Promise<ListPage<IBasketDelivery>> {
    return restapi.get<ListPage<IBasketDelivery>>(`salaty_core/basket_delivery/`).then(r => r.data);
}

