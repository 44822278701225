import { ListPage, PaginationParams } from '../types/commons';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { IBasketItem } from '../types/basket';
import { getBasketItems } from '../services/basket-item';


export function useGetBasketItems(basketId: number, params: PaginationParams = { pageNumber: 1 }, sorting?: string[]): UseQueryResult<ListPage<IBasketItem>> {
    return useQuery(['basket-item', basketId, params, sorting], () => getBasketItems(basketId, params, sorting), {
        retry: false
    });
}

export function useInvalidateBasketItems(basketId: number, params: PaginationParams = { pageNumber: 1 }) {
    const queryClient = useQueryClient();
    return useMutation(() => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({queryKey: ['basket-item', basketId, params]})
    });
}
