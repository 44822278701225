import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
    Button,
    Link,
    MainNavigationItem,
    SubNavigation,
    SubNavigationContent,
    SubNavigationHeader,
    SubNavigationItem,
    SubNavigationLink,
    useMainNavigation,
    User,
} from "@wfp/react";
import { IUserInfo } from '../../../types/auth';
import { useInvalidateUser, useOptionalUser } from '../../../hooks/auth';
import { clearToken } from '../../../services/auth';
import { RouteGuardConfig } from '../../../AppRouting';


const UserMenu: React.FC = () => {
  const {t} = useTranslation();
  const user: IUserInfo | undefined = useOptionalUser();
  const { mutate } = useInvalidateUser();
  const { onChangeSub } = useMainNavigation();

  const logout = () => {
      mutate(user, {
          onSuccess: () => {
              clearToken();
              window.location.href = '/oidc/total_logout?next=/oidc/logout_done/&fail=/';
          }
      });
      return;
  }

  return (
    <>
    {!!user && (
    <MainNavigationItem className="hidden md:block" subNavigation={
        <SubNavigation>
            <SubNavigationContent children={
                RouteGuardConfig.filter(item => !!item.label).filter(
                    (item) => ((item.roles.includes('*') && !!user!.role) || item.roles.includes(user!.role || '')) && !item.blocked?.includes(user!.role || '')
                ).map((item, idx) => (
                    <SubNavigationItem key={idx} className='text-center'>
                        <NavLink to={item.routing} onClick={() => onChangeSub('close')}>{t(item.label!)}</NavLink>
                    </SubNavigationItem>
                ))
            }/>
        </SubNavigation>
    }>
        <Link>{t('menu')}</Link>
    </MainNavigationItem>
    )}
    {!!user && (
    <MainNavigationItem
      className="wfp--main-navigation__user"
      subNavigation={
        <SubNavigation>
          <SubNavigationHeader data-testid="user-menu-dropdown">
            <SubNavigationItem className="">{user!.firstName} {user!.lastName}</SubNavigationItem>
            <SubNavigationItem className="">{user!.email}</SubNavigationItem>
          </SubNavigationHeader>
          <SubNavigationContent>
            <SubNavigationLink>
              <Button
                kind="secondary"
                small
                onClick={logout}
                data-testid="user-menu-logout-button"
              >
                Logout
              </Button>
            </SubNavigationLink>
          </SubNavigationContent>
        </SubNavigation>
      }
    >
      <User showName name={`${user!.firstName} ${user!.lastName}`} />
    </MainNavigationItem>
    )}
    </>
  );
};

export default UserMenu;
