import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IBeneficiary } from "../../types/beneficiary";
import { ConfigurableTable, TableProps } from '../commons/configurable-table/configurable-table';

function BeneficiaryTable(props: TableProps<IBeneficiary>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleRowClick(item: IBeneficiary) {
    navigate(`/beneficiaries/${item.id}/`);
  }

  return (
        <ConfigurableTable {...{
          ...props,
          onRowClick: handleRowClick,
          headers: ["IsActive", "Email",  "IDMID", "Address"],
          fields: ["isActive", "email",  "idmId", "address"],
          sortables: [
              { field: "isActive", code: "isActive", mode: 0, enabled: false },
              { field: "idmId", code: "idmId", mode: 0, enabled: true },
              { field: "email", code: "email", mode: 0, enabled: true },
              { field: "address", code: "address", mode: 0, enabled: false },
          ],
          formatters: {
            isActive: (item: IBeneficiary) => item.isActive ? t('yes') : t('no')
          }
        }} />
  );
}

export default BeneficiaryTable;
