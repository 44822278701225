import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { IBeneficiary } from "../types/beneficiary";
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import { getBeneficiaries, getBeneficiary } from "../services/beneficiary";

export default function useGetBeneficiaries(params: PaginationParams, filters?: KVPair[], sorting?: string[]): UseQueryResult<
  ListPage<IBeneficiary>
> {
  return useQuery(
    ["beneficiaries", params, filters, sorting],
    () => getBeneficiaries(params, filters, sorting),
    {
      retry: false,
    }
  );
}

export function useGetBeneficiary(id: number, enabled?: boolean): UseQueryResult<IBeneficiary> {
  return useQuery(["beneficiary", id], () => getBeneficiary(id), {
    retry: false,
    enabled: enabled
  });
}

export function useInvalidateBeneficiary(id: number) {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["beneficiary", id] }),
  });
}
