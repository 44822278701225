import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@wfp/react";
import { IDelivery } from "../../types/delivery";
import { bulkCreateCycle } from "../../services/cycle";
import BulkForm from "./delivery-bulk-form";
import { useInvalidateCycle } from "../../hooks/cycle";
import { useInvalidateDeliveries } from "../../hooks/delivery";

interface Props {
  item?: IDelivery;
  className?: string;
  cycleId: number;
}

export interface IBulkError {
  baskets?: string[];
  beneficiary?: string[];
  generic?: string[];
}
export default function DeliveryAddEdit(props: Props) {
  const [baskets, setBaskets] = useState<number[]>([]);
  const [allBeneficiaries, setAllBeneficiaries] = useState<number>(1);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [bulkError, setBulkError] = useState<IBulkError>();
  const labelText = !!props.item ? t("Edit") : t("Add");
  const { mutate: invalidateCycle } = useInvalidateCycle(props.cycleId);
  const { mutate: invalidateDelivery } = useInvalidateDeliveries();

  const handleAddItem = () => {
    setOpenModal(true);
    setBulkError(undefined);
    setBaskets([]);
    setAllBeneficiaries(1);
  };

  const handleAddItemSubmit = () => {
    if (baskets.length === 0) {
      setBulkError({
        ...bulkError,
        baskets: [t("select_at_least_one_basket")],
      });
    }
    if (allBeneficiaries === 0) {
      setBulkError({
        ...bulkError,
        beneficiary: [t("select_beneficiary")],
      });
    } else {
      bulkCreateCycle(props.cycleId, baskets, allBeneficiaries)
        .then((r) => {
          invalidateCycle();
          invalidateDelivery();
          handleAddItemClose();
        })
        .catch((e) => {
          setBulkError({
            ...bulkError,
            generic: [e[0]],
          })
        });
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setBulkError(undefined);
  };

  return (
    <div className={props.className}>
      <Button kind="secondary" onClick={handleAddItem} className="">
        {labelText + " " + t("Delivery")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddItemClose}
        onRequestSubmit={handleAddItemSubmit}
        modalHeading={t("selectAtLeastOneBasket")}
        modalLabel={labelText + " " + t("Delivery")}
        primaryButtonText={labelText}
        secondaryButtonText={t("Cancel")}
        width="full"
        lazyLoad
        children={
          <BulkForm
            onChangeBaskets={setBaskets}
            onChangeBeneficiary={setAllBeneficiaries}
            baskets={baskets}
            errors={bulkError}
          />
        }
      />
    </div>
  );
}
