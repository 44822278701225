import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import translatorEn from "./assets/locales/en/translation.json";
import translatorAr from "./assets/locales/ar/translation.json";

const resources = {
  en: {
    translation: translatorEn,
  },
  ar: {
    translation: translatorAr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || "en",
  debug: false,
  fallbackLng: "en",
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
