import axios, {AxiosResponse} from "axios";
import { KVPair, PaginationParams, ServerError } from '../types/commons';
import { getLanguage } from '../components/layouts/header/LangMenu';
import { clearToken, getToken } from './auth';

export function formatQueryOptions(params: PaginationParams, filters?: KVPair[], sorting?: string[]): string {
    const options = [{key: 'page', value: String(params.pageNumber)}, {key: 'page_size', value: String(params.pageSize || '')}];
    if (!!sorting) {
        options.push({key: '__sort__', value: sorting.toString()});
    }
    return buildQueryString(!!filters ? filters.concat(options) : options);
}

export function buildQueryString(values?: KVPair[]): string {
    let qs: string = '';
    (values || []).forEach((pair) => {
        const c = `${pair.key}=${(pair.value || '').toString()}`;
        qs = !!qs ? `${qs}&${c}` : c;
    });
    return qs;
}

export const restapi = axios.create({
    baseURL: process.env.REACT_APP_REST_API_BASE_URL,
});

restapi.defaults.xsrfCookieName = 'csrftoken';
restapi.defaults.xsrfHeaderName = 'X-CSRFToken';


restapi.interceptors.request.use(async config => {
    const c = {...config, headers: config.headers || {}, };
    const token = getToken();
    if (token) {
        c.headers['Authorization'] = `Token ${token}`;
    }
    const lang = getLanguage();
    if (lang) {
        c.headers['X-USER-LANGUAGE'] = lang;
    }
    return c;
});


restapi.interceptors.response.use((response: AxiosResponse) => response, (error) => {
    if (!!error.response && error.response.status  === 401) {
        clearToken();
        window.location.href = '/oidc/authenticate?next=/home&fail=/';
        return;
    }
    return Promise.reject(!!error.response ? new ServerErrorResponseInfo(error.response) : error);
});

class ServerErrorResponseInfo implements ServerError {

    detail: [string] | undefined;
    errors: [string] | undefined;
    status?: number;

    constructor(response: any) {
        if (response.data) {
            Object.assign(this, response.data);
        } else {
            this.detail = undefined;
            this.errors = undefined;
        }
        this.status = response.status;
    }
}





