import { useTranslation } from "react-i18next";
import { Form, NumberInput, TextArea, TextInput } from "@wfp/react";
import { IBasketItem } from "../../types/basket";
import { IBasketItemError } from "../../models/validate-basket-item";

interface Props {
  error?: IBasketItemError;
  item: IBasketItem;
  onChange: (item: IBasketItem) => void;
}

function BasketItemForm(props: Props) {
  const { t } = useTranslation();

  return (
    <Form>
      <div className="grid grid-cols-2 gap-5 ">
        <TextInput
          className="mb-5"
          invalid={!!props.error?.nameAr}
          invalidText={t(props.error?.nameAr?.toString() || "")}
          labelText={t("nameAr")}
          onChange={(e: any) =>
            props.onChange({ ...props.item, nameAr: e.target.value })
          }
          value={props.item.nameAr || ""}
        />
        <TextInput
          invalid={!!props.error?.nameEn}
          invalidText={t(props.error?.nameEn?.toString() || "")}
          labelText={t("nameEn")}
          onChange={(e: any) =>
            props.onChange({ ...props.item, nameEn: e.target.value })
          }
          value={props.item.nameEn || ""}
        />
      </div>
      <div className="grid grid-cols-2 gap-5">
        <TextArea
          className="mb-5"
          invalid={!!props.error?.descriptionAr}
          invalidText={t(props.error?.descriptionAr?.toString() || "")}
          labelText={t("descriptionAr")}
          onChange={(e: any) =>
            props.onChange({ ...props.item, descriptionAr: e.target.value })
          }
          value={props.item.descriptionAr || ""}
        />
        <div>
          <TextArea
            invalid={!!props.error?.descriptionEn}
            invalidText={t(props.error?.descriptionEn?.toString() || "")}
            labelText={t("descriptionEn")}
            onChange={(e: any) =>
              props.onChange({ ...props.item, descriptionEn: e.target.value })
            }
            value={props.item.descriptionEn || ""}
          />
        </div>
      </div>

      <NumberInput
        invalid={!!props.error?.quantity}
        invalidText={t(props.error?.quantity?.toString() || "")}
        labelText={t("quantity")!}
        value={props.item.quantity || 1}
        onChange={(e: any) =>
          props.onChange({ ...props.item, quantity: e.target.value })
        }
        name="quantity"
      />
    </Form>
  );
}

export default BasketItemForm;
