import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Role } from "../types/auth";
import { useLoggedUser } from "../hooks/auth";
import { LoadingCenter } from "../components/commons/loading-center";
import { WfpLogoStandardBlackEn } from "@wfp/icons-react";
import { Link, Text } from "@wfp/react";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/commons/logo";

export const Home = () => {
  const navigate = useNavigate();
  const user = useLoggedUser();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!user && !!navigate) {
      if ([Role.Beneficiary].includes(user.role)) {
        navigate(`/mobile`);
      }
    }
  }, [user, navigate]);

  if (!user) {
    return <LoadingCenter />;
  }

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col">
        <Logo />
        <div className="space-x-5 text-center">
          <Link href="/beneficiaries" linkSolid>
            {t("Beneficiaries")}
          </Link>
          <Link href="/cycles" linkSolid>
            {t("Cycles")}
          </Link>
          <Link href="/baskets" linkSolid>
            {t("Baskets")}
          </Link>
        </div>
      </div>
    </div>
  );
};
