import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { IBasket } from "../types/basket";
import { PaginationParams, ListPage, KVPair } from "../types/commons";
import { getBasketDetail, getBaskets } from "../services/basket";

export function useGetBaskets(
  params: PaginationParams,
  filters?: KVPair[],
  sorting?: string[]
): UseQueryResult<ListPage<IBasket>> {
  return useQuery(
    ["basket", params, filters, sorting],
    () => getBaskets(params, filters, sorting),
    {
      retry: false,
    }
  );
}

export function useGetBasket(id: number): UseQueryResult<IBasket> {
  return useQuery(["basket", id], () => getBasketDetail(id), {
    retry: false,
  });
}

export function useInvalidateBaskets(
  params: PaginationParams = { pageNumber: 1 }
) {
  const queryClient = useQueryClient();
  return useMutation(() => Promise.resolve(), {
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["basket", params] }),
  });
}
