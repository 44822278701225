import {ServerError} from "../types/commons";
import {IBasketItem} from "../types/basket";


export interface IBasketItemError extends ServerError {
    descriptionAr: string[] | undefined;
    descriptionEn: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    quantity: string[] | undefined;
}

class BasketItemErrorInfo implements IBasketItemError {
    basket: string[] | undefined;
    descriptionAr: string[] | undefined;
    descriptionEn: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    quantity: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateBasketItem(item: IBasketItem): Promise<IBasketItem> {
    const error = new BasketItemErrorInfo();
    if (!item.basket) {
        error.add('basket', 'this_field_is_required');
    }
    if (!item.descriptionAr) {
        error.add('descriptionAr', 'this_field_is_required');
    }
    if (!item.descriptionEn) {
        error.add('descriptionEn', 'this_field_is_required');
    }
    if (!item.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!item.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    if (!item.quantity) {
        error.add('quantity', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
