import {
  DateRangePicker as WfpDatePicker,
  Button,
  Form,
  Modal,
  Checkbox,
  Text,
  BlockNotification,
} from "@wfp/react";
import { t } from "i18next";
import ReactDatePicker from "react-datepicker";
import { DeliveryState, IDelivery } from "../../types/delivery";
import { useState } from "react";
import { format } from "date-fns";
import { useGetBaskets, useInvalidateBaskets } from "../../hooks/basket";
import { useGetCycle } from "../../hooks/cycle";
import { useTranslation } from "react-i18next";
import { useInvalidateDeliveries } from "../../hooks/delivery";
import { saveDelivery } from "../../services/delivery";

interface DeliveryErrorInfo {
  scheduledFrom?: string;
  scheduledTo?: string;
  generic?: string[];
}

interface FormProps {
  item: IDelivery;
  baskets: number[];
  onChangeBaskets: (baskets: number[]) => void;

  onChangeDate: (
    delivery: IDelivery,
    field: "scheduledFrom" | "scheduledTo"
  ) => void;
  error?: DeliveryErrorInfo;
}
function EditForm(props: FormProps) {
  const { data: basket, isLoading, isError } = useGetBaskets({ pageNumber: 1 });
  const {
    data: cycle,
    isLoading: isCycleLoading,
    isError: isCycleError,
  } = useGetCycle(props.item.cycle);

  const [startDate, setStartDate] = useState<Date | null>(
    props.item?.scheduledFrom ? new Date(props.item.scheduledFrom) : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    props.item?.scheduledTo ? new Date(props.item?.scheduledTo) : null
  );
  const formattedStartDate = startDate ? format(startDate, "MM/dd/yyyy") : "";
  const formattedEndDate = endDate ? format(endDate, "MM/dd/yyyy") : "";

  const handleSelectDate = (selectedDate: Date, dateType: "from" | "to") => {
    const date = format(selectedDate, "yyy-MM-dd");
    if (dateType === "from") {
      props.onChangeDate(
        { ...props.item, scheduledFrom: date },
        "scheduledFrom"
      );
      setStartDate(selectedDate);
    } else if (dateType === "to") {
      props.onChangeDate({ ...props.item, scheduledTo: date }, "scheduledTo");
      setEndDate(selectedDate);
    }
  };

  const onCheckBasket = (id: number) => {
    if (props.baskets.includes(id)) {
      props.onChangeBaskets(props.baskets.filter((b) => b !== id));
    } else {
      props.onChangeBaskets([...props.baskets, id]);
    }
  };

  return (
    <Form>
      <div className="space-y-5">
      
        {props.item.state === DeliveryState.BOOKED && (
          <WfpDatePicker
            labelText={t("selectDateRange")}
            datePicker={ReactDatePicker}
            startDate={formattedStartDate}
            endDate={formattedEndDate || formattedStartDate}
            fromProps={{
              selected: startDate,
              onSelect: (date: Date) => handleSelectDate(date, "from"),
              error: !!props.error?.scheduledFrom,
              minDate: cycle?.dateFrom && new Date(cycle?.dateFrom),
              maxDate: cycle?.dateTo && new Date(cycle?.dateTo),
            }}
            toProps={{
              selected: endDate,
              onSelect: (date: Date) => handleSelectDate(date, "to"),
              disabled: !startDate,
              minDate: cycle?.dateFrom && new Date(cycle?.dateFrom),
              maxDate: cycle?.dateTo && new Date(cycle?.dateTo),
            }}
          />
        )}

        {props.error?.scheduledFrom && (
          <p className="text-red-500">
            {t(props.error?.scheduledFrom?.toString() || "")}
          </p>
        )}
        {props.error?.scheduledTo && (
          <p className="text-red-500">
            {t(props.error?.scheduledTo?.toString() || "")}
          </p>
        )}
        {!!basket && (
          <div className="wfp--form-item">
            <Text className="wfp--label">{t("Baskets")}</Text>
            <div className="overflow-y-scroll max-h-60 w-full">
              {basket.results.map((b, idx) => (
                <div className="">
                  <Checkbox
                    name={b.name}
                    value={b.id}
                    onChange={(e) => {
                      onCheckBasket(Number(e.target.value));
                    }}
                    key={idx}
                    labelText={b.name}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Form>
  );
}

interface EditDeliveryProps {
  item: IDelivery;
  className?: string;
  cycleId: number;
}

export default function DeliveryEdit(props: EditDeliveryProps) {
  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery | null>(
    props.item
  );
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState<DeliveryErrorInfo>();
  const [baskets, setBaskets] = useState<number[]>([]);
  const { t } = useTranslation();
  const { mutate: invalidateDelivery } = useInvalidateDeliveries();
  const { mutate: invalidateBaskets } = useInvalidateBaskets();

  const handleChangeDelivery = (delivery: IDelivery) => {
    setSelectedDelivery(delivery);
  };

  const handleSubmit = () => {
    saveDelivery(selectedDelivery as IDelivery)
      .then((res) => {
        invalidateDelivery();
        invalidateBaskets();
        setOpenModal(false);
      })
      .catch((err) => {
        setError(err as DeliveryErrorInfo);
      });
  };

  return (
    <div className={props.className}>
      <Button
        kind="secondary"
        onClick={() => setOpenModal(!openModal)}
        className=""
      >
        {t("Edit") + " " + t("Delivery")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={() => setOpenModal(!openModal)}
        onRequestSubmit={handleSubmit}
        modalLabel={t("Edit") + " " + t("Delivery")}
        primaryButtonText={t("Save")}
        secondaryButtonText={t("Cancel")}
        width="full"
        lazyLoad
        children={
          <EditForm
            item={props.item}
            onChangeDate={handleChangeDelivery}
            error={error}
            baskets={baskets}
            onChangeBaskets={setBaskets}
          />
        }
      />
    </div>
  );
}
