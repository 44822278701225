import { formatQueryOptions, restapi } from './restapi';
import { KVPair, ListPage, PaginationParams } from '../types/commons';
import { IDelivery } from "../types/delivery";

export function getDeliveries(params: PaginationParams, filters?: KVPair[], sorting?: string[]): Promise<ListPage<IDelivery>> {
   const qs = formatQueryOptions(params, filters, sorting);
   return restapi.get<ListPage<IDelivery>>(`/api/v1/salaty_core/delivery/?${qs}`).then((res) => ({...res.data, filtering: filters, sorting: sorting}));
}

export function getDeliveryDetail(id: number): Promise<IDelivery> {
    return restapi.get<IDelivery>(`/api/v1/salaty_core/delivery/${id}/`).then((res) => res.data);
}

export function saveDelivery(data: IDelivery): Promise<IDelivery> {
    if (!data.id) {
        return restapi.post<IDelivery>('/api/v1/salaty_core/delivery/', data).then((res) => res.data);
    } else {
        return restapi.patch<IDelivery>(`/api/v1/salaty_core/delivery/${data.id}/`, data).then((res) => res.data);
    }
}

export function confirmDelivery(id: number): Promise<IDelivery> {
    return restapi.post<IDelivery>(`/api/v1/salaty_core/delivery/${id}/confirm/`).then((res) => res.data);
}