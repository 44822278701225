import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ICycle } from "../../types/cycle";
import { ICycleError } from "../../models/validate-cycle";
import { saveCycle } from "../../services/cycle";
import { useNavigate } from "react-router-dom";
import { validateCycle } from "../../models/validate-cycle";
import { Button, Modal } from "@wfp/react";
import CycleForm from "./cycle-form";
import { useInvalidateCycle } from "../../hooks/cycle";

interface Props {
  item?: ICycle;
  className?: string;
  kind?: "primary" | "secondary";
}
function CycleAddEdit(props: Props) {
  const [selectedCycle, setSelectedCycle] = useState<ICycle>();
  const [cycleError, setCycleError] = useState<ICycleError>();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const labelText = !!props.item ? t("Edit") : t("Add");
  const { mutate } = useInvalidateCycle(props.item?.id || 0);

  const handleAddItem = () => {
    if (!!props.item) {
      setSelectedCycle(props.item);
    } else {
      setSelectedCycle({} as ICycle);
    }
    setOpenModal(true);
    setCycleError(undefined);
  };

  const handleCycleChange = (
    updatedCycle: ICycle,
    updatedField: "nameAr" | "nameEn" | "dateFrom" | "dateTo"
  ) => {
    setCycleError({ ...cycleError, [updatedField]: undefined } as ICycleError);
    setSelectedCycle(updatedCycle);
  };

  const handleAddItemSubmit = () => {
    if (!!selectedCycle) {
      validateCycle(selectedCycle)
        .then((cycle) =>
          saveCycle(cycle)
            .then((res) => {
              setOpenModal(false);
              setCycleError(undefined);
              setSelectedCycle(res);
              mutate();
              navigate(`/cycles/${res.id}`);
            })
            .catch((e) => setCycleError(e as ICycleError))
        )
        .catch((e) => setCycleError(e));
    }
  };

  const handleAddItemClose = () => {
    setOpenModal(false);
    setCycleError(undefined);
    setSelectedCycle(undefined);
  };

  return (
    <div className={props.className}>
      <Button kind={props.kind} onClick={handleAddItem} >
        {labelText + " " + t("Cycle")}
      </Button>
      <Modal
        open={openModal}
        onRequestClose={handleAddItemClose}
        onRequestSubmit={handleAddItemSubmit}
        modalHeading={labelText + " " + t("Cycle")}
        primaryButtonText={labelText}
        secondaryButtonText={t("Cancel")}
        width="full"
        lazyLoad
        children={
          <CycleForm
            item={selectedCycle!}
            onChange={handleCycleChange}
            error={cycleError}
          />
        }
      />
    </div>
  );
}

export default CycleAddEdit;
