import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronRight } from "@wfp/icons-react";

function MobileHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDeliveries = () => navigate("/mobile/deliveries");

  return (
    <>
      <div className="flex h-screen">
        <div className="mt-20 mb-auto w-full">
          <div className="flex flex-col space-y-10 items-center font-bold">
            <div
              className="flex m-5 wfp--card-box cursor-pointer px-4 py-12 rounded-lg w-4/5 h-1/3"
              onClick={handleDeliveries}
            >
              <div className="flex items-center">
                {t("YourCurrentDeliveries")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileHome;
