interface Props {
  name: string;
  labelText: string;
  helperText?: string;
  required?: boolean;
  value: number;
  onChange?: (e: any) => void;
}

const CheckBox = (props: Props) => {
  const { name, labelText } = props;

  return (
    <div
      data-testid={"checkbox"}
      className={`wfp--form-item wfp--checkbox-wrapper`}
    >
      <input
        value={props.value}
        type="checkbox"
        onChange={props.onChange}
        className={`wfp--checkbox `}
        id={name}
      />
      <label
        htmlFor={name}
        className="wfp--checkbox-label"
        title={name || undefined}
      >
        <span className="">{labelText}</span>
      </label>
      <div className="wfp--form-requirement">
        <span>{}</span>
      </div>
    </div>
  );
};

export default CheckBox;
