import {ServerError} from "../types/commons";
import {IBasket} from "../types/basket";


export interface IBasketError extends ServerError {
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
}

class BasketErrorInfo implements IBasketError {
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateBasket(item: IBasket): Promise<IBasket> {
    const error = new BasketErrorInfo();
    if (!item.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!item.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(item) : Promise.reject(error);
}
