import "./App.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import i18next from "./i18n";
import AppRouting from "./AppRouting";

function App() {
  const { i18n } = useTranslation();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <div className="App " dir={i18n.language === "ar" ? "rtl" : "ltr"}>
          <AppRouting />
        </div>
      </I18nextProvider>
    </QueryClientProvider>
  );
}

export default App;
